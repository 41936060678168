<template>
  <div class="actions">
    <BrandButton
      :size="sizes.xSmall"
      :outlined="!options.leftItem.isActive"
      :target="options.leftItem.route"
      as="router-link"
    >
      <VueText
        :color="options.leftItem.isActive ? 'white-100' : 'currentColor'"
        sizeLevel="4"
        weightLevel="3"
        >{{ options.leftItem.label }}</VueText
      >
    </BrandButton>
    <BrandButton
      :size="sizes.xSmall"
      :outlined="!options.rightItem.isActive"
      :target="options.rightItem.route"
      as="router-link"
    >
      <VueText
        :color="options.rightItem.isActive ? 'white-100' : 'currentColor'"
        sizeLevel="4"
        weightLevel="3"
        >{{ options.rightItem.label }}</VueText
      >
    </BrandButton>
  </div>
</template>
<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants';

export default {
  name: 'BrandTwinTabs',
  data() {
    return {};
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  components: {
    BrandButton,
    VueText,
  },
  computed: {
    icon() {
      return {
        sheetLines: ICON_VARIABLES.sheetLines,
        speechBubble: ICON_VARIABLES.speechBubble,
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
  },
};
</script>
<style scoped lang="scss">
.actions {
  display: flex;
  padding: palette-space-level(20);
  z-index: 5;
  a:not(:first-child) {
    margin-left: palette-space-level(10);
  }
  a:not(:last-child) {
    margin-right: palette-space-level(10);
  }
}
</style>
